<template>
	<ButtonBlock
		v-bind="ctaDirective"
		:url="acceptRequestUrl"
		:background-color="color"
		:text-color="textColor"
		class="accept-request-button"
		:class="{ 'only-icon-on-mobile': onlyIconOnMobile }"
	>
		<Icon
			name="material-symbols:handshake-outline-sharp"
			class="partnership-icon"
		/>

		<span class="button-text">Realizar parceria</span>
		<span v-if="onlyIconOnMobile" class="tooltip">Aceitar</span>
	</ButtonBlock>
</template>

<script setup lang="ts">
import ButtonBlock from '@SHARED/components/blocks/ButtonBlock.vue';
import type { CtaTriggerSource } from '@SHARED/utils/declareVCtaDirective';

defineOptions({ name: 'AcceptPartnershipButton' });

type AvailableButtonColor = 'black' | 'white';

type AcceptPartnershipButtonProps = {
	source: CtaTriggerSource | null;
	acceptRequestUrl: string;
	color: AvailableButtonColor;
	onlyIconOnMobile?: boolean;
	useCtaDirective?: boolean;
};

const props = withDefaults(defineProps<AcceptPartnershipButtonProps>(), {
	onlyIconOnMobile: false,
	source: null,
	useCtaDirective: false
});

const textColor = computed<AvailableButtonColor>(() =>
	props.color === 'black' ? 'white' : 'black'
);

const ctaDirective = computed(() => {
	if (!props.useCtaDirective) return {};

	return {
		'v-cta': {
			action: 'make-partnership',
			trigger: props.source
		}
	};
});
</script>

<style lang="scss" scoped>
@import '@SHARED/assets/style/mixins/breakpoint.scss';

.accept-request-button {
	display: flex;
	gap: 0.5rem;

	@include screen-up(md) {
		gap: 0.75rem;
	}

	&.only-icon-on-mobile {
		padding: 0.63rem;
		position: relative;

		@include screen-down(md) {
			border-radius: var(--pill-border-radius);
		}

		.button-text {
			display: none;

			@include screen-up(md) {
				display: initial;
			}
		}

		.tooltip {
			display: none;
		}

		&:hover {
			background-color: v-bind(color);
		}

		&:hover .tooltip {
			@include screen-down(md) {
				display: initial;
				position: absolute;
				top: calc(100% + 0.25rem);
				padding: 1px 0.25rem;
				background-color: var(--darkgray);
				color: var(--white);
				text-wrap: nowrap;

				font-size: 0.75rem;
				font-weight: 500;
				z-index: 1;
			}
		}
	}
}

.partnership-icon {
	width: 1.5rem;
	height: 1.5rem;

	@include screen-up(md) {
		width: 1.75rem;
		height: 1.75rem;
	}
}
</style>
